import React from 'react';
import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import SignupService from '../services/signup.service';
import { withRouter } from '../common/with-router';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import LoginImage from '../common/logo.PNG';

function Signup(props) {
    const [credentials, setCredentials] = React.useState({ name: '', permissions: '', password: '' });
    const [success, setSuccess] = React.useState(false); // New state for success message
    const [failed, setFailed] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    // Function to handle account creation
    const handleSignup = async (e) => {
        e.preventDefault();
        setFailed(false); // Reset failure state before submitting
        setSuccess(false); // Reset success state before submitting

        try {
            console.log(credentials)
            const response = await SignupService.createAccount(credentials);
            if (response.data.success) {
                // Handle successful account creation
                setSuccess(true); // Set success state to true
            } else {
                // Handle error from the server (user already exists, etc.)
                setErrorMessage(response.data.message || "Error creating account");
                setFailed(true);
            }
        } catch (err) {
            console.error(err);
            setErrorMessage("An error occurred while creating the account.");
            setFailed(true);
        }
    };

    const handleChange = e => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    };

    return (
        <Box 
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: 'auto',
                alignItems: 'center',
                rowGap: 2,
                marginTop: 0,
                color: 'rgba(245, 245, 245)',
                paddingLeft: "420px"
            }}>
            {/* Signup form container */}
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 525,
                    height: 600,
                    borderRadius: 9,
                    boxShadow: 10,
                    padding: 4,
                    mt: '10vh',
                }}
            >
                {/* Center logo */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginBottom: 4,
                    }}
                >
                    <img src={LoginImage} alt="Mousemate logo" height="175" />
                    <Typography variant="h5" color="black" sx={{ fontWeight: 450, mt: 2 }}>
                        Sign Up with Mousemate
                    </Typography>
                </Box>

                {/* Input fields */}
                <TextField
                    label="Username"
                    name="name"
                    value={credentials.name}
                    onChange={handleChange}
                    sx={{ width: '80%', marginBottom: 2 }} // Adjusted width
                    error={failed}
                />
                <TextField
                    label="Password"
                    type="password"
                    name="password"
                    value={credentials.password}
                    onChange={handleChange}
                    sx={{ width: '80%', marginBottom: 2 }} // Adjusted width
                    error={failed}
                    helperText={failed ? errorMessage : ""}
                />
                <TextField
                    label="Permissions"
                    name="permissions"
                    value={credentials.permissions}
                    onChange={handleChange}
                    sx={{ width: '80%', marginBottom: 2 }} // Adjusted width
                    error={failed}
                />
                <Button
                    variant="contained"
                    sx={{ mt: 2 }}
                    onClick={handleSignup}
                    endIcon={<ArrowCircleRightOutlinedIcon />}
                >
                    Sign Up
                </Button>
                {success && (
                    <Typography variant="body1" color="green" sx={{ marginTop: 2 }}>
                        Account created successfully!
                    </Typography>
                )}
                {failed && (
                    <Typography variant="body1" color="red" sx={{ marginTop: 2 }}>
                        {errorMessage}
                    </Typography>
                )}
            </Paper>
        </Box>
    );
}

export default withRouter(Signup);