import React from 'react';
import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import { withRouter } from '../common/with-router';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import LoginImage from '../common/logo.PNG';
import LoginService from '../services/login.service';

function ChangePassword(props) {
    const [credentials, setCredentials] = React.useState({ name: '', password: '', code: '' });
    const [success, setSuccess] = React.useState(false);
    const [failed, setFailed] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    const handleChange = (e) => {
      setCredentials({ ...credentials, [e.target.name]: e.target.value });
      setFailed(false);
      setSuccess(false);
    };

    // Function to handle account creation
    const handleForgotPassword = async (e) => {
      e.preventDefault();
      try {
          console.log(`Requesting password reset:`, credentials);
          const response = await LoginService.changePassword(credentials);
          if (response.data.success) {
              setSuccess(true);
          } else {
              setErrorMessage('Invalid credentials or error processing request.');
              setFailed(true);
          }
      } catch (err) {
          console.error(err);
          setErrorMessage('An error occurred while resetting the password.');
          setFailed(true);
      }
    };

    return (
      <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                rowGap: 2,
                paddingTop: '10vh',
                paddingLeft: '420px'
            }}
        >
            {/* Forgot Password Form Container */}
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 525,
                    borderRadius: 9,
                    boxShadow: 10,
                    padding: 4,
                }}
            >
                {/* Logo and Header */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginBottom: 4,
                    }}
                >
                    <img src={LoginImage} alt="Logo" height="175" />
                    <Typography variant="h5" color="black" sx={{ fontWeight: 450, mt: 2 }}>
                        Forgot Your Password?
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
                        Enter your details to reset your password.
                    </Typography>
                </Box>

                {/* Input Fields */}
                <TextField
                    label="Username"
                    name="name"
                    value={credentials.name}
                    onChange={handleChange}
                    sx={{ width: '80%', marginBottom: 2 }}
                    error={failed}
                />
                <TextField
                    label="New Password"
                    name="password"
                    type="password"
                    value={credentials.password}
                    onChange={handleChange}
                    sx={{ width: '80%', marginBottom: 2 }}
                    error={failed}
                />
                <TextField
                    label="Code"
                    name="code"
                    value={credentials.code}
                    onChange={handleChange}
                    sx={{ width: '80%', marginBottom: 2 }}
                    error={failed}
                />

                {/* Submit Button */}
                <Button
                    variant="contained"
                    sx={{ mt: 2 }}
                    onClick={handleForgotPassword}
                    endIcon={<ArrowCircleRightOutlinedIcon />}
                >
                    Submit
                </Button>

                {/* Success Message */}
                {success && (
                    <Typography variant="body1" color="green" sx={{ marginTop: 2 }}>
                        Password reset successful!
                    </Typography>
                )}

                {/* Failure Message */}
                {failed && (
                    <Typography variant="body1" color="red" sx={{ marginTop: 2 }}>
                        {errorMessage}
                    </Typography>
                )}
            </Paper>
        </Box>
    );
}

export default withRouter(ChangePassword);