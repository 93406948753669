import http from "../http-common";

const checkUser = (user) => {
    return http.post(`/login`, user)
}

const changePassword = (user) => {
    return http.post('/login/change-password', user)
}
const LoginService = {
    checkUser,
    changePassword
};

export default LoginService;