import http from "../http-common";

const createAccount = (user) => {
    return http.post(`/login/createAccount`, user)
}

const SignupService = {
    createAccount
};

export default SignupService;