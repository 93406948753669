import * as React from 'react';
import Title from './title.component'
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DataGrid} from '@mui/x-data-grid';
import { withRouter } from '../common/with-router';
import CageService from '../services/cage.service';
import ageFromDate from '../common/ageFromDate';

const columns = [
    {field: 'roomId', headerName: 'Room #', width: 75, align: 'center'},
    {field: 'id', headerName: 'Cage #', width: 100},
    {field: 'generation', headerName: 'Gen #', width: 75, hidden: true},
    {field: 'strain', headerName: 'Strain', width: 155},
    {field: 'status', headerName: 'Cage Type', width: 100},
    {field: 'gender', headerName: 'Sex', width: 65},
    {field: 'age', headerName: 'Age', width: 65},
    {field: 'count', headerName: '# Mice', width: 75},
    {field: 'notes', headerName: 'Cage Notes', width: 375},
    {field: 'claim', headerName: 'Claimant', width: 85},
    {field: 'claimType', headerName: 'Claim', width: 125},
    
];

function createRows(cages) {
    if(!cages){
        return []
    }
    let rows = [];
    cages.forEach((cage) => {
        rows.push({
            roomId: cage.roomId,
            id: cage.id,
            strain: (cage.strain + genotypedMouseInCage(cage.mice)),
            claim: cage.claim,
            claimType: claimReasons(cage.mice),
            status: cage.status,
            gender: cage.gender,
            age: ageFromCage(cage),
            count: cage.count,
            generation: genFromCage(cage),
            notes: cage.notes
        })
    })
    return rows
}

function genotypedMouseInCage(mice){
    if(mice?.some((mouse) => mouse.genotype !== null)){
        return " (genotyped)"
    }
    else{
        return ""
    }
}
export {genotypedMouseInCage}

function claimReasons(mice) {
        const uniqueReasons = ([...new Set(mice?.map(mouse => mouse.claim))]).filter((element) => {return element !== null});
        var separator = "";
        if(uniqueReasons?.length > 1){
            separator = ", ";
        }
        return (uniqueReasons.join(separator));
}

function ageFromCage(cage) {
    if(!cage){return null}
    let cageMice = cage?.mice;
    if(cageMice?.length !== 0){
        return ageFromDate(cageMice[0].dob)
    }
    else{
        return null
    }
}

function genFromCage(cage) {
    if(!cage?.mice){
        return ""
    }
    if(cage?.mice?.length === 0){
        return ""
    }
    var generations = [];
    cage.mice.forEach((mouse) => {
        if(generations.indexOf(mouse.generation) === -1) {
            generations.push(mouse.generation);
        }
    })
    generations?.sort();
    return (generations.length === 0 ? "" : generations.join(", "))
}

function CageList(props) {
    const [tableRows, setTableRows] = React.useState([]);
    const [showEmpty, setShowEmpty] = React.useState(false);
    const [filterModel, setFilterModel] = React.useState(() => {
        const savedFilters = localStorage.getItem('cageListFilters');
        const defaultFilterModel = { items: [], logicOperator: 'and' };
        try {
            return savedFilters ? JSON.parse(savedFilters) : defaultFilterModel;
        } catch (error) {
            console.warn('Invalid filterModel in localStorage, resetting to default.');
            return defaultFilterModel;
        }
    });
    const validFilterModel = filterModel && filterModel.items ? filterModel : { items: [] };

    const navigate = useNavigate();
    const goToCage = (cageNumber) => {
        navigate('/event/' + cageNumber)
    }
    const handleFilterModelChange = (newFilterModel) => {
        setFilterModel(newFilterModel);
        localStorage.setItem('cageListFilters', JSON.stringify(newFilterModel));
    };
    
    React.useEffect(() => {
        CageService.getEmptyOrNot(showEmpty,false)
        .then(response => {
            setTableRows(createRows(response.data));
        })
        .catch(e => {
            console.log(e)
        });
    }, [showEmpty, props.reload])

    const [sortModel, setSortModel] = React.useState([
        {
          field: 'id',
          sort: 'desc',
        },
      ]);

return (
    <React.Fragment>
        { tableRows.length > 0 &&
            <>
            <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 1
            }}>
                <Title>All Cages</Title>
                <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    columnGap: 2
                }}>
                    <Button
                    size="small"
                    sx={{
                        borderRadius: 4,
                        backgroundColor: showEmpty ? "#1976d2" : "transparent",
                        color: showEmpty ? "#fff" : "#1976d2",
                        border: "1px solid #1976d2",
                        "&:hover": {
                        backgroundColor: showEmpty ? "#115293" : "#e3f2fd",
                        },
                    }}
                    onClick={() => setShowEmpty(!showEmpty)}
                    variant={showEmpty ? "contained" : "outlined"}
                    >
                    Showing Empty Cages
                    </Button>
                </Box>
            </Box>
            <Box>
                <DataGrid
                rows={tableRows}
                columns={columns}
                checkboxSelection={false}
                disableRowSelectionOnClick={false}
                filterModel={validFilterModel}
                onFilterModelChange={handleFilterModelChange}
                onRowDoubleClick={(params) => {
                    goToCage(params.row.id)
                }}
                sortModel={sortModel}
                rowHeight={40}
                onSortModelChange={(model) => setSortModel(model)}
                sx={{
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                      outline: "none !important",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
                      outline: "none",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell": {
                      whiteSpace: "normal !important",
                      wordWrap: "break-word !important",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: "#f5f5f5",
                      fontWeight: "bold",
                      fontSize: "0.9rem",
                      textAlign: "center",
                    },
                    "& .MuiDataGrid-row": {
                      "&:nth-of-type(odd)": {
                        backgroundColor: "#fafafa",
                      },
                      "&:hover": {
                        backgroundColor: "#f1f1f1",
                      },
                    },
                    "& .MuiDataGrid-columnHeader": {
                      textAlign: "center",
                    },
                    "& .MuiDataGrid-cell": {
                      padding: "8px",
                    },
                    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                      height: "8px",
                    },
                    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
                      backgroundColor: "#e0e0e0",
                      borderRadius: "4px",
                    },
                    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
                      backgroundColor: "#bdbdbd",
                      borderRadius: "4px",
                    },
                    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
                      backgroundColor: "#9e9e9e",
                    },
                    fontFamily: "'Roboto', 'Arial', sans-serif",
                    maxHeight: "67vh",
                    borderRadius: "8px",
                    border: "1px solid #e0e0e0",
                  }}
                    />
            </Box>
            </>
        }
    </React.Fragment>
)
}
  export default withRouter(CageList)